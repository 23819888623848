import 'material-symbols';

      import * as html from '@atzu/alexi_html';
      import * as md from '@atzu/alexi_html_material';

      import { initializeApp } from 'firebase/app';
      import { getAuth, connectAuthEmulator } from 'firebase/auth';
      import {
        getFirestore,
        connectFirestoreEmulator,
      } from 'firebase/firestore';

      import { setupApps } from '@atzu/alexi/apps/setup';
      import { apps } from '@atzu/alexi/apps/registry';
      import { settings as globalSettings } from '@atzu/alexi/conf';
      import { IndexedDBBackend } from '@atzu/alexi/db/backends/indexeddb';
      import { dispatch } from '@atzu/alexi/dispatcher';

      import { PorssisahkoApp } from './src/porssisahko_net/config/app';
      import { EnergyApp } from './src/energy/config/app';
      import { AlexiFirebaseApp } from './src/@atzu/alexi_firebase/config/app';
      import { DatahubApp } from './src/datahub/config/app';

      const settings = globalSettings;

      async function main() {
        html.Form.define('html-form');
        html.UList.define('html-ul');
        html.LI.define('html-li');
        html.Button.define('html-button');
        html.Input.define('html-input');
        html.Select.define('hmtl-select');
        html.Option.define('html-option');

        // Cards

        md.OutlinedCard.define('md3-outlined-card');
        md.ElevatedCard.define('md3-elevated-card');

        // Common buttons

        md.ElevatedButton.define('md3-elevated-button');
        md.FilledButton.define('md3-filled-button');
        md.OutlinedButton.define('md3-outlined-button');
        md.TextButton.define('md3-text-button');
        md.TonalButton.define('md3-tonal-button');

        // Icon buttons

        md.FilledIconButton.define('md3-filled-icon-button');
        md.StandardIconButton.define('md3-standard-icon-button');
        md.TonalIconButton.define('md3-tonal-icon-button');

        // Layout

        md.Align.define('md3-align');
        md.Center.define('md3-center');
        md.Column.define('md3-column');
        md.Container.define('md3-container');
        md.Expanded.define('md3-exanded');
        md.Fragment.define('md3-fragment');
        md.LayoutBuilder.define('md3-layout-builder');
        md.Row.define('md3-row');
        md.Scrollable.define('md3-scrollable');
        md.Stack.define('md3-stack');
        md.Wrap.define('md3-wrap');

        // Other

        md.MaterialApp.define('md3-material-app');
        md.AnimatedOpacity.define('md3-animated-opacity');
        md.Avatar.define('md3-avatar');
        md.Fab.define('md3-fab');
        md.Icon.define('md3-icon');
        md.Overlay.define('md3-overlay');
        md.Ripple.define('md3-ripple');
        md.Typography.define('md3-typography');
        md.Scaffold.define('md3-scaffold');
        md.ListTile.define('md3-list-tile');
        md.ListView.define('md3-list-view');
        md.Menu.define('md3-menu');
        md.Dialog.define('md3-dialog');
        md.Divider.define('md3-divider');
        md.DividerLine.define('md3-divider-line');

        // Navigation

        md.NavigationBar.define('md3-navigation-bar');
        md.Navigator.define('md3-navigator');
        md.NavigationDestination.define('md3-navigation-destination');
        md.ActiveIndicator.define('md3-active-indicator');
        md.NavigationDrawer.define('md3-navigation-drawer');
        md.NavigationDrawerDestination.define(
          'md3-navigation-drawer-destination',
        );
        md.NavigationDrawerActiveIndicator.define(
          'md3-navigation-drawer-active-indicator',
        );

        // Progress

        md.LinearProgress.define('md3-linear-progress');
        md.Track.define('md3-linear-progress-track');
        md.Indicator.define('md3-linear-progress-indicator');

        // Bars

        md.AppBar.define('md3-app-bar');
        md.TopAppBar.define('md3-top-app-bar');
        md.TabBar.define('md3-tab-bar');
        md.TextField.define('md3-text-field');
        md.Tab.define('md3-tab');
        md.TabActiveIndicator.define('md3-tab-active-indicator');

        // Sheets

        md.BottomSheet.define('md3-bottom-sheet');

        /**
         * Initialize Firebase SDK.
         */

        // Initialize Firebase app
        const app = initializeApp({
          apiKey: import.meta.env.SITE_FIREBASE_API_KEY,
          authDomain: import.meta.env.SITE_FIREBASE_AUTH_DOMAIN,
          projectId: import.meta.env.SITE_FIREBASE_PROJECT_ID,
          storageBucket: import.meta.env.SITE_FIREBASE_STORAGE_BUCKET,
          messagingSenderId: import.meta.env.SITE_FIREBASE_MESSAGING_SENDER_ID,
          appId: import.meta.env.SITE_FIREBASE_APP_ID,
          measurementId: import.meta.env.SITE_FIREBASE_MEASUREMENT_ID,
        });

        const auth = getAuth(app);
        const db = getFirestore();

        if (import.meta.env.MODE === 'development') {
          self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
          connectAuthEmulator(auth, `http://localhost:5004`);
          connectFirestoreEmulator(db, 'localhost', 5002);
        }

        // TODO: Activate Analytics and reCAPTCHA
        //
        // const analytics = getAnalytics(app);

        // // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
        // // key is the counterpart to the secret key you set in the Firebase console.
        // const appCheck = initializeAppCheck(app, {
        //   provider: new ReCaptchaV3Provider(
        //     import.meta.env.VITE_APP_FIREBASE_APP_CHECK_SITE_KEY,
        //   ),

        //   // Optional argument. If true, the SDK automatically refreshes App Check
        //   // tokens as needed.
        //   isTokenAutoRefreshEnabled: true,
        // });

        /**
         * Setup the Alexi project.
         */

        await setupApps({
          FIREBASE: {
            APP: app,
            AUTH: auth,
          },
          SPA_ENTRY: 'energy',
          INSTALLED_APPS: [
            //
            EnergyApp,
            DatahubApp,
            PorssisahkoApp,
            AlexiFirebaseApp,
          ],
          APPEND_SLASH: true,
          DATABASES: {
            default: {
              NAME: 'default',
              ENGINE: IndexedDBBackend,
            },
          },
        });

        /**
         * Create the IndexedDB database tables.
         */

        const backend = new settings.DATABASES.default.ENGINE();
        await backend.init(settings.DATABASES.default.NAME, 11, (db) => {
          for (const appName in apps) {
            for (const modelName in apps[appName].models) {
              const model = apps[appName].models[modelName];

              if (!db.objectStoreNames.contains(model.meta.dbTable)) {
                db.createObjectStore(model.meta.dbTable, {
                  keyPath: 'id',
                  autoIncrement: true,
                });
              }
            }
          }
        });

        /**
         * Listen for popstate events to enable SPA navigation.
         */

        window.addEventListener('popstate', (event) => {
          event.preventDefault();
          dispatch({ method: 'get', url: location.pathname, action: false });
        });

        /**
         * Dispatch the initial request.
         */

        dispatch({
          method: 'get',
          url: window.location.pathname + window.location.search,
        });
      }

      main();