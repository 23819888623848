import { AppConfig } from '@atzu/alexi/apps/config';

export class EnergyApp extends AppConfig {
  name = 'energy';
  getUrls = async () => await import(`../urls`);
  getModels = async () => await import(`../models`);
  getViews = async () => await import(`../views`);
  getTemplate = async (templatesDirName: string, templateName: string) => {
    const [appName, name] = templateName.split('/');
    return await import(`../${templatesDirName}/${appName}/${name}.ts`);
  };
}
